  window.formatDateISO = function(year, month, day) {
    var dateFormatted = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
    return dateFormatted;
  };
  
  // Formats country info for showing in tooltips and other. Removes unreal country areas '???????????'.
  // "Country_name (Continent_name) [area_1 + area_2 + area_n]"
  // "Spain (Europe) [Madrid + Segovia]"
  window.formatCountryInfo = function(continent_name, country_name, country_areas) {
    const UNKNOWN = '???????????';
    var area_names_filtered = country_areas.filter(area => area['name'] != UNKNOWN).map(area => area['name']);
    country_areas_formatted = '';
    if (area_names_filtered.length > 0) {
      country_areas_formatted = ' [' + area_names_filtered.join(' + ') + ']';
    }
    return (country_name + ' (' + continent_name + ')' + country_areas_formatted).replace("'", "&#39;");
  };

  // From an array of values it returns an array of colors where
  //   the highest value receives colorMaxHex
  //   the lowest value receives colorMinHex
  //   every other value inbetween receives a color inbetween the two extreme colors
  window.rangeColorsHex = function(values, colorMinHex, colorMaxHex) {
    const [colorMinR, colorMinG, colorMinB] = colorHexToDec(colorMinHex);
    const [colorMaxR, colorMaxG, colorMaxB] = colorHexToDec(colorMaxHex);
    const [valueMin, valueMax] = minMaxAboveZero(values);
    colorsForValues = [];
    for (let i = 0; i < values.length; i++) {
      iColor = null;
      switch (values[i]) {
        case 0:
          iColor = colorMinHex;
          break;
        case valueMax:
          iColor = colorMaxHex;
          break;
        case valueMin:
          iColor = colorMinHex;
          break;
        default:
          intermediateDirect = (values[i] - valueMin) / (valueMax - valueMin);
          intermediateInverse = 1 - intermediateDirect;
          intermediateR = ((colorMinR < colorMaxR) ? intermediateDirect : intermediateInverse);
          intermediateG = ((colorMinG < colorMaxG) ? intermediateDirect : intermediateInverse);
          intermediateB = ((colorMinB < colorMaxB) ? intermediateDirect : intermediateInverse);
          minR = ((colorMinR < colorMaxR) ? colorMinR : colorMaxR);
          maxR = ((colorMinR < colorMaxR) ? colorMaxR : colorMinR);
          minG = ((colorMinG < colorMaxG) ? colorMinG : colorMaxG);
          maxG = ((colorMinG < colorMaxG) ? colorMaxG : colorMinG);
          minB = ((colorMinB < colorMaxB) ? colorMinB : colorMaxB);
          maxB = ((colorMinB < colorMaxB) ? colorMaxB : colorMinB);
          r = parseInt((intermediateR * (maxR - minR)) + minR);
          g = parseInt((intermediateG * (maxG - minG)) + minG);
          b = parseInt((intermediateB * (maxB - minB)) + minB);
          iColor = colorDecToHex(r, g, b);
      }
      colorsForValues.push(iColor);
    }
    return colorsForValues;
  };

  window.colorHexToDec = function(colorHex) {
    return [parseInt(colorHex.substring(1,3), 16), parseInt(colorHex.substring(3,5), 16), parseInt(colorHex.substring(5,7), 16)];
  };
  
  window.colorDecToHex = function(r, g, b) {
    return '#' + r.toString(16) + g.toString(16) + b.toString(16);
  };

  window.minMaxAboveZero = function(values) {
    var min = null;
    var max = null;
    for (let i = 0; i < values.length; i++) {
      if (values[i] > 0) {
        if (min == null || values[i] < min) min = values[i];
        if (max == null || values[i] > max) max = values[i];
      }
    }
    return [min, max];
  };

  window.addPanToCurrentLocationButton = function (map, iconPath, tooltip) {
    const GMAPS_MAX_SAFE_ZOOM_LEVEL = 18;
    if (navigator.geolocation) { //Browser must support HTML5 geolocation
      const panToCurrentPosDiv = document.createElement('div');
      panToCurrentPosDiv.style.backgroundColor = '#fff';
      panToCurrentPosDiv.style.borderRadius = '2px';
      panToCurrentPosDiv.style.cursor = 'pointer';
      panToCurrentPosDiv.style.marginBottom = '10px';
      panToCurrentPosDiv.style.marginRight = '10px';
      panToCurrentPosDiv.style.padding = '7px';
      var panToCurrentPosImg = document.createElement('img');
      panToCurrentPosImg.src = iconPath;
      panToCurrentPosImg.title = tooltip;
      panToCurrentPosImg.height = 26;
      panToCurrentPosDiv.appendChild(panToCurrentPosImg);
      panToCurrentPosDiv.index = -1;
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(panToCurrentPosDiv);
      panToCurrentPosDiv.addEventListener('click', () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = { lat: position.coords.latitude, lng: position.coords.longitude };
            map.setCenter(pos);
            maxZoomService = new google.maps.MaxZoomService();
            maxZoomService.getMaxZoomAtLatLng(pos, (result) => {
              newZoomLevel = (result.status == 'OK' ? result.zoom : GMAPS_MAX_SAFE_ZOOM_LEVEL);
              map.setZoom(newZoomLevel);
            });
          },
          () => {}
        );
      });
    }
  };