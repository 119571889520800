// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

export const rails_ujs = require("@rails/ujs")
rails_ujs.start()
window.Rails = rails_ujs

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('custom/common')
require('custom/users')
require('custom/photos')
require('blueimp-load-image')
import loadImage from 'blueimp-load-image'
window.loadImage = loadImage


//= require jquery3
//= require popper
//= require bootstrap-sprockets


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

